import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';

import { cn } from '../utils/cn';

export type ThemedFileInputProps =
  React.InputHTMLAttributes<HTMLInputElement> & {
    label: string;
    buttonLabel?: string;
    error?: string;
    optional?: string;
    disabled?: boolean;
    hint?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onValueChange?: (value: string) => void;
  };

export const ThemedFileInput = forwardRef<
  HTMLInputElement,
  ThemedFileInputProps
>(
  (
    {
      label,
      error,
      hint,
      onChange,
      buttonLabel,
      onValueChange,
      optional,
      ...props
    },
    ref
  ) => {
    const uuid = useMemo(() => Math.random().toString(36).substring(7), []);
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => inputRef.current!);

    // const [value, setValue] = useState<File | undefined>(undefined);

    return (
      <div>
        <label>
          {label && (
            <p
              className={cn(
                'text-theme-inputs-labelFontSize text-theme-inputs-labelTextColor mb-2',
                error && 'text-theme-inputs-errorColor'
              )}
            >
              {label} {optional && `(${optional})`}
            </p>
          )}
        </label>
        <div className="relative">
          <input
            {...props}
            required={!optional}
            type="file"
            ref={inputRef}
            onChange={(e) => {
              onChange?.(e);
              onValueChange?.(e.target.value);
            }}
            aria-invalid={!!error}
            className={cn(
              'transition-all flex w-full px-3 py-2',
              'disabled:cursor-not-allowed disabled:opacity-50',
              'text-theme-inputs-fontSize border-theme-inputs-borderColor text-theme-inputs-textColor',
              'theme-inputs-textColor bg-theme-inputs-backgroundColor [border-width:var(--inputs-borderWidth)] [border-radius:var(--inputs-cornerRadius)]',
              'placeholder-theme-inputs-placeholderColor min-h-[44px]',
              '[box-shadow:var(--inputs-shadow)] leading-normal',
              'focus-visible:border-theme-general-focusColor focus-visible:outline-1 focus-visible:outline-theme-general-focusColor outline-offset-0 outline-none',
              error &&
                'border-theme-inputs-errorColor focus-visible:border-theme-inputs-errorColor focus-visible:!outline-theme-inputs-errorColor text-theme-inputs-errorColor'
            )}
            aria-describedby={
              hint && !error
                ? uuid + 'hint'
                : error
                  ? uuid + 'error'
                  : undefined
            }
          />
        </div>
        {hint && (
          <p
            id={uuid + 'hint'}
            className="text-theme-inputs-hintAndErrorFontSize text-theme-inputs-hintTextColor mt-1"
          >
            {hint}
          </p>
        )}
        {error && typeof error === 'string' && (
          <p
            id={uuid + 'error'}
            className="text-theme-inputs-hintAndErrorFontSize text-theme-inputs-errorColor mt-1"
          >
            {error}
          </p>
        )}
      </div>
    );
  }
);

ThemedFileInput.displayName = 'ThemedFileInput';
