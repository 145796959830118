import { CSSProperties } from 'react';
import bitsColors from '@bits/tailwind-config/bits-colors';

import { cn } from './utils/cn';

export type Score = {
  value: number;
  amount: number;
};

export const ScoreRadioGroup = ({
  scores,
  onValueChange,
  colors = [
    bitsColors['kiwi-light'],
    bitsColors['khaki'],
    bitsColors['tangerine-light'],
    bitsColors['grapefruit'],
  ],
}: {
  scores: Score[];
  onValueChange: (score: number) => Promise<void>;
  colors?: string[];
}) => {
  return (
    <div className="flex w-full min-w-16 gap-3" role="radiogroup">
      {scores.map((score) => {
        return (
          <button
            type="button"
            style={
              {
                '--color': colors?.[score.value - 1],
              } as CSSProperties
            }
            className={cn(
              'group flex gap-2 justify-center bits-text-button-2 w-full rounded-md h-10 items-center border bg-fog-light',
              'transition-all duration-50',
              'hover:border-ink'
            )}
            key={score.value}
            onClick={() => onValueChange(score.value)}
          >
            <p className="flex flex-col justify-center py-1.5">
              {score.amount}
            </p>
            <p className="flex flex-col justify-center rounded border border-[color-mix(in_srgb,var(--color),transparent)] bg-white px-3 py-1.5 group-hover:bg-[color-mix(in_srgb,var(--color),transparent)]">
              {score.value}
            </p>
          </button>
        );
      })}
    </div>
  );
};
