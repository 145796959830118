'use client';

import { ElementType, HTMLProps, ReactNode, useState } from 'react';
import { CaretUpDown, Check } from '@phosphor-icons/react';

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from './command';
import {
  Popover,
  PopoverContent,
  PopoverPrimitive,
  PopoverTrigger,
} from './popover';
import { cn } from './utils/cn';

export type SearchableSelectProps<
  Option extends { label: string; value: string } = {
    label: string;
    value: string;
  },
> = {
  options: Option[];
  multiselect?: boolean;
  placeholder: ReactNode;
  emptyText: string;
  searchText?: string;
  values?: Option['value'][];
  truncateValue?: boolean;
  onValuesChange?: (value: Option['value'][]) => void;
  icon?: ElementType;
  renderOption?: (option: Option) => JSX.Element;
  renderValues?: (values: string[], options: Option[]) => JSX.Element;
  renderTrigger?: (options: {
    triggerProps: Pick<HTMLProps<HTMLButtonElement>, 'role' | 'aria-expanded'>;
    values: string[];
    options: Option[];
    placeholder: ReactNode;
  }) => JSX.Element;
  checkboxAlignment?: 'left' | 'right';
  disabled?: boolean;
};

/**
 * A controlled searchable select component, combobox.
 *
 * @example
 * const [values, setValues] = useState<string[]>(['option1']);
 * ...
 * <SearchableSelect
 *   values={values}
 *   options={[
 *     { label: 'Option 1', value: 'option1' },
 *     { label: 'Option 2', value: 'option2' },
 *     { label: 'Option 3', value: 'option3' },
 *   ]}
 *   multiselect
 *   placeholder="Select options"
 *   emptyText="No options found"
 *   onValuesChange={setValues}
 * />
 */
export function SearchableSelect<
  Option extends { label: string; value: string } = {
    label: string;
    value: string;
  },
>({
  options,
  values = [],
  multiselect,
  placeholder,
  searchText,
  emptyText,
  truncateValue = true,
  onValuesChange,
  icon: Icon,
  renderOption,
  renderValues,
  renderTrigger,
  checkboxAlignment = 'left',
  disabled,
}: SearchableSelectProps<Option>) {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        {renderTrigger?.({
          triggerProps: {
            role: 'combobox',
            'aria-expanded': open,
          },
          values,
          options,
          placeholder,
        }) || (
          <button
            role="combobox"
            aria-expanded={open}
            className={cn(
              'transition-colors bg-white flex items-center justify-between min-h-11 gap-2 text-left w-full color-ink rounded-md border border-input px-3 py-2 bits-text-body-1 placeholder:text-smoke focus-visible:outline-none focus-visible:border-ink',
              'disabled:cursor-not-allowed disabled:bg-fog disabled:text-smoke',
              open && 'border-ink'
            )}
          >
            {Icon && <Icon className="size-5 shrink-0" />}
            <div className={cn(truncateValue && 'truncate', 'w-full')}>
              {values.length === 0
                ? placeholder
                : renderValues?.(values, options) ||
                  values
                    .map((val) => options.find((i) => i.value === val)?.label)
                    .join(', ')}
            </div>
            <CaretUpDown className="ml-2 size-4 shrink-0 opacity-50" />
          </button>
        )}
      </PopoverTrigger>
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
          className={cn(
            'relative overflow-hidden z-10 bg-white',
            'animate-in fade-in-0 data-[side=bottom]:mt-1 data-[side=top]:mb-1',
            'data-[side=bottom]:slide-in-from-top-2 data-[side=top]:slide-in-from-bottom-2',
            'p-0 border-theme-inputs-borderColor [border-width:var(--inputs-borderWidth)]',
            'min-w-[var(--radix-popover-trigger-width)] max-w-[calc(var(--radix-popper-available-width)-16px)] max-h-[calc(var(--radix-popper-available-height)-16px)]'
          )}
          asChild
        >
          <Command>
            <CommandInput placeholder={searchText} />
            <CommandEmpty>{emptyText}</CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  key={option.value}
                  value={option.label}
                  className={cn(
                    'flex items-center gap-2',
                    checkboxAlignment === 'right' && 'justify-between'
                  )}
                  onSelect={() => {
                    if (multiselect) {
                      if (values.includes(option.value)) {
                        onValuesChange?.(
                          values.filter((value) => value !== option.value)
                        );
                      } else {
                        const newValues = [...values, option.value];
                        onValuesChange?.(newValues);
                      }
                    } else {
                      onValuesChange?.([option.value]);
                      setOpen(false);
                    }
                  }}
                >
                  {checkboxAlignment === 'left' && (
                    <CheckIndicator
                      multiselect={multiselect}
                      selected={values.includes(option.value)}
                    />
                  )}
                  {renderOption ? renderOption(option) : option.label}
                  {checkboxAlignment === 'right' && (
                    <CheckIndicator
                      multiselect={multiselect}
                      selected={values.includes(option.value)}
                    />
                  )}
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>
    </Popover>
  );
}

const CheckIndicator = ({ multiselect = false, selected = false }) => (
  <span
    className={cn(
      'flex size-5 shrink-0 items-center justify-center rounded-md text-ink',
      multiselect && 'border border-fog'
    )}
  >
    <Check
      weight="bold"
      className={cn('h-4 w-4', selected ? 'opacity-100' : 'opacity-0')}
    />
  </span>
);
