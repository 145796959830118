import { request } from 'http';
import i18n from 'i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import cases from '../public/locales/en/cases.json';
import common from '../public/locales/en/common.json';
import entities from '../public/locales/en/entities.json';
import errors from '../public/locales/en/errors.json';
import login from '../public/locales/en/login.json';
import monitoring from '../public/locales/en/monitoring.json';
import onboarding from '../public/locales/en/onboarding.json';
import reports from '../public/locales/en/reports.json';
import risk from '../public/locales/en/risk.json';
import workflows from '../public/locales/en/workflows.json';

export const resources = {
  en: {
    common,
    entities,
    login,
    onboarding,
    reports,
    monitoring,
    workflows,
    risk,
    cases,
    errors,
  },
} as const;

export type TranslationKeys = (typeof resources)['en'] & {
  // Local lazy namespaces
  shared: Record<string, string>;
  questionnaire: Record<string, string>;
  'industry-codes': Record<string, string>;
  // API namespaces
  'kyb-questionnaire': Record<string, string>;
  'kyc-questionnaire': Record<string, string>;
};

export const localLazyResources = ['questionnaire', 'industry-codes'] as const;

export const apiResources = [
  'shared',
  'kyb-questionnaire',
  'kyc-questionnaire',
] as const;

export const i18nBackendLoadpath = '/api/locales/{{lng}}/{{ns}}';

i18n
  .use(initReactI18next)
  .use(I18NextHttpBackend)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    supportedLngs: ['en', 'fi', 'sv', 'da', 'no'],
    partialBundledLanguages: true,
    resources,
    react: {
      useSuspense: false,
    },
    defaultNS: 'common',
    ns: [
      // Local namespaces
      'common',
      'workflows',
      'onboarding',
      'reports',
      'login',
      'monitoring',
      'entities',
      'risk',
      'cases',
      'errors',
    ],
    backend: {
      loadPath: i18nBackendLoadpath,
      apiResources,
      request: async (
        options: { apiResources: string[] },
        url: string,
        _: unknown,
        callback: (
          error: unknown,
          result: { data: unknown; status: number } | null
        ) => void
      ) => {
        const [_0, _1, _2, lang, namespace] = url.split('/');

        // load api namespace
        if (options.apiResources.includes(namespace as string)) {
          const request = await fetch(`/api/locales/${lang}/${namespace}`);
          if (request.ok) {
            callback(null, { data: await request.json(), status: 200 });
          } else {
            callback(new Error('not found'), null);
          }
          return;
        }

        // load local namespace
        if (
          namespace &&
          localLazyResources.includes(
            namespace as (typeof localLazyResources)[number]
          )
        ) {
          const request = await fetch(`/locales/${lang}/${namespace}.json`);
          if (request.ok) {
            callback(null, { data: await request.json(), status: 200 });
          } else {
            callback(new Error('not found'), null);
          }
          return;
        }

        // tell i18next that we don't have the remote namespace, use fallback
        callback(new Error('no remote namespace'), null);
      },
    },
    interpolation: {
      escapeValue: false,
    },
  })
  .then();

export default i18n;
