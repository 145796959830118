import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { CircleFlag, getPhoneNumberExample, ISO3166Alpha2 } from '@bits/i18n';
import {
  formatPhoneNumberIntl as _formatPhoneNumberIntl,
  formatPhoneNumber,
  getCountries,
  getCountryCallingCode,
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from 'react-phone-number-input';

import { ThemedInput, ThemedSearchableSelect } from '.';
import { cn } from '../utils/cn';
import { countryCodeToName } from '../utils/country-code';

export type ThemedPhoneNumberInputProps = {
  label?: string;
  error?: string;
  hint?: string;
  value?: string;
  onValueChange?: (v?: string) => void;
  className?: string;
  defaultCountryCode?: ISO3166Alpha2;
  language?: string;
  placeholder: string;
  emptyText: string;
  disabled?: boolean;
};

export const validatePhoneNumber = (phoneNumber: string) =>
  // Only allow -, (, ), +, digits and spaces, then verify the possibility
  /^[0-9\-\(\)\s\+]+$/.test(phoneNumber) && isPossiblePhoneNumber(phoneNumber);

export const formatPhoneNumberIntl = (phoneNumber: string) =>
  //@ts-expect-error string
  _formatPhoneNumberIntl(phoneNumber);

type Countries = ReturnType<typeof getCountries>[number];

export const ThemedPhoneNumberInput = forwardRef<
  HTMLInputElement,
  ThemedPhoneNumberInputProps
>(
  (
    {
      defaultCountryCode = 'SE',
      value,
      language = 'en',
      onValueChange,
      label,
      error,
      hint,
      emptyText,
      placeholder,
      disabled,
    }: ThemedPhoneNumberInputProps,
    inputRef
  ) => {
    const [countryCode, setCountryCode] = useState<ISO3166Alpha2>(
      parsePhoneNumber(value || '')?.country || defaultCountryCode
    );
    const parsedNumber = parsePhoneNumber(value || '');
    const selectedCallcode = getCountryCallingCode(countryCode as Countries);

    const mappedCallCodes = useMemo(() => mapCallCodes(language), [language]);

    const example = getPhoneNumberExample(countryCode);
    const formattedExample = formatPhoneNumber(
      //@ts-expect-error string
      '+' + selectedCallcode + example
    ).trim();

    const valueWithoutCallCode = value
      ?.replace(`+${parsedNumber?.countryCallingCode}`, '')
      ?.replace(`+${selectedCallcode}`, '')
      .trimStart();

    useEffect(() => {
      onValueChange?.(`+${selectedCallcode}${valueWithoutCallCode}`);
    }, [selectedCallcode]);

    // const handleBlur = () => {
    //   const parsed = parsePhoneNumber(value || '', countryCode);
    //   onValueChange?.(parsed?.formatInternational());
    // };

    return (
      <div className="grid gap-1">
        <p
          className={cn(
            'text-theme-inputs-labelFontSize text-theme-inputs-labelTextColor',
            error && 'text-theme-inputs-errorColor'
          )}
        >
          {label}
        </p>
        <div className="@container flex gap-2">
          <div className="@w-md:w-[140px] w-[128px] shrink-0">
            <ThemedSearchableSelect
              values={[countryCode]}
              emptyText={emptyText}
              placeholder={placeholder}
              error={!!error}
              onValuesChange={([v]) => {
                setCountryCode(v as ISO3166Alpha2);
              }}
              disabled={disabled}
              options={mappedCallCodes}
              renderOption={(option) => (
                <div className="flex w-full items-center gap-2 overflow-hidden">
                  <CircleFlag
                    className="size-5 shrink-0"
                    countryCode={option.value}
                  />
                  <div className="w-full truncate">
                    <span>{option.label}</span>
                  </div>
                </div>
              )}
              renderValues={([countryCode]) => {
                const callCode = getCountryCallingCode(
                  countryCode as Countries
                );
                return (
                  <div className="text-theme-inputs-fontSize flex items-center gap-2">
                    <CircleFlag
                      className="@w-md:size-5 size-4 shrink-0"
                      countryCode={countryCode as string}
                    />
                    (+{callCode})
                  </div>
                );
              }}
            />
          </div>

          <div className="w-full">
            <ThemedInput
              type="tel"
              ref={inputRef}
              onValueChange={(v) => onValueChange?.(`+` + selectedCallcode + v)}
              value={valueWithoutCallCode}
              error={!!error}
              aria-label={label}
              placeholder={formattedExample}
              disabled={disabled}
              // onBlur={handleBlur}
            />
          </div>
        </div>

        {(hint || error) && (
          <p
            className={cn(
              'text-theme-inputs-labelTextColor text-theme-inputs-hintAndErrorFontSize',
              error &&
                'text-theme-inputs-errorColor text-theme-inputs-hintAndErrorFontSize'
            )}
          >
            {error || hint}
          </p>
        )}
      </div>
    );
  }
);

ThemedPhoneNumberInput.displayName = 'ThemedPhoneNumberInput';

const mapCallCodes = (language: string) =>
  getCountries()
    .map((countryCode) => ({
      label: `(+${getCountryCallingCode(countryCode)}) ${countryCodeToName(
        countryCode,
        language
      )}`,
      value: countryCode,
      data: {
        name: countryCodeToName(countryCode, language) || '',
      },
    }))
    .sort((a, b) => a.data.name.localeCompare(b.data.name));
